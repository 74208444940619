import React, { Component } from 'react';

// import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from '../components/layout';
import SEO from "../components/seo";

import './casestudybocajets.scss';

import imgQuote from "../images/quotation_mark.webp";

// import SimpleModal from '../components/utilities/SimpleModal';

class OneImageFullTwo extends Component {
    render() {
        const divStyles = {
            backgroundImage: 'url('+this.props.imageOne+')',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundColor: this.props.backgroundColor
        };
        return (
            <div className="container-fluid one-image-wrapper p-0">
                <div className="row align-items-center no-gutters">
                    <div className="col-12 bgImg" style={divStyles}>

                    </div>
                </div>
            </div>
        );
    }
}

export default ({pageContext}) => (
    <Layout>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image.source_url}
            link={pageContext.link}
            />        
        <div className="case-study-two-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="header-container" style={{backgroundImage: `url(${pageContext.acf.header_image.csTwoHedImaLocalFile.childImageSharp.fluid.src})`}}>
                        <img src={imgQuote} alt=""/>
                        <h2>{pageContext.acf.header_text}</h2>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-12">
                        <img src={pageContext.acf.client_logo.source_url} className="client-logo" width={pageContext.acf.client_logo.width} height={pageContext.acf.client_logo.height} alt={pageContext.yoast.opengraph_title} />
                    </div>
                    <div className="col-12">
                        <div className="bar"></div>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="first-paragraph">
                            <p>Boca Jets Lacrosse, founded in 2004, currently has over 350 boys and girls on the active roster. It is one of several organizations playing within the SFYLL (South Florida Youth Lacrosse League) which fields teams from Miami to Jupiter.</p>
                        </div>
                        <a href="https://www.meritmile.com/bocajetslacrosse/" className="btn sitebtn" target="_blank" rel="noopener noreferrer">Learn More</a>
                    </div>
                    <div className="col-12 col-md-6">
                        <img src="https://www.meritmile.com/uploads/2018/11/boca-jets-lacrosse-desktop.jpg" className="first-paragraph-image img-fluid" width="623" height="512" alt="" />
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="">
                            <h3>Challenge</h3>
                            <p>Completely re-imagine the visual brand identity in an effort to elevate Boca Jets Lacrosse as one of the leading youth sports associations nationwide – a complete identity package involving a new Boca Jets Lacrosse logo, updated website, signage, team uniforms, and new merchandise.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container single-image-text-wrapper detail-wrapper">
                <div className="row">
                    <div className="col-md-6">
                        <img src="https://www.meritmile.com/uploads/2019/03/group-8@2x.jpg" className="img-fluid" width="558" height="722" alt="" />
                    </div>
                    <div className="col-md-6 text">
                        <div className="text">
                            <h3>Solutions</h3>
                            <p>Starting with market research and ongoing focus groups, Merit Mile performed a comprehensive branding exploration that weighed input from key stakeholders and an assigned "brand team". Ultimately, the new identity yielded a modern logo, refreshed website and social media properties, team uniforms, and promotional merchandise; including tailgate tents, embroidered hats, and conventional outerwear. The new brand has been depicted as fun, confident, and a brand that echoes values in boys and girls competition, sportsmanship and community.</p>
                            <h3>Results</h3>
                            <p>The reimagined brand identity extends the newly defined brand essence across the team uniforms, web site, signage, and new merchandise. Merit Mile is also executing a media plan across all appropriate social media platforms.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid cta">
                <div className="row">
                    <div className="col-12">
                        <h2 className="ctaTitle">Start Creating Business Momentum Today</h2>
                        <a href="#footer" className="btn btn-light">Get In Touch</a>
                    </div>
                </div>
            </div>
            <div className="container blockquote">
                <div className="row">
                    <div className="col-12">
                        <div className="text">
                            <div className="row">
                                <div className="col-12 col-md-6 two-col-img-left">
                                    <h3>Deliverables</h3>
                                    <ul>
                                        <li>Brand identity exploration</li>
                                        <li>Focus group and market research</li>
                                        <li>Logo and brand guidelines</li>
                                        <li>Website refresh</li>
                                        <li>Social media platform refresh</li>
                                        <li>Team uniforms</li>
                                        <li>Branded merchandise</li>
                                        <li>Email templates</li>
                                        <li>Press release</li>
                                    </ul>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="third-paragraph">
                                        <img className="desktop-image img-fluid" src="https://www.meritmile.com/uploads/2019/03/bjl-facebook-tablet@2x.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <OneImageFullTwo width={pageContext.childWordPressAcf1ImageFull.image.media_details.width/2} height={pageContext.childWordPressAcf1ImageFull.image.media_details.height/2} imageOne={pageContext.childWordPressAcf1ImageFull.image.source_url} backgroundColor={pageContext.childWordPressAcf1ImageFull.image_full_bg_color} />
            <div className="container two-image-wrapper">
                <div className="row align-items-center">
                    <div className="col-md-5">
                        <img src="https://www.meritmile.com/uploads/2019/03/boca-jets-tent-mockup-lrg@2x.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-7">
                        <img src="https://www.meritmile.com/uploads/2019/03/boca-jets-hats@2x.jpg" className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 website-btn text-center">
                        <a href="http://bocajetslacrosse.com/" className="btn sitebtn" target="_blank" rel="noopener noreferrer">View Website</a>
                    </div>
                </div>
            </div>
            <div className="container quote-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="text">
                            <p>"We are beyond thrilled for this branding partnership with Merit Mile… I am especially excited for this opportunity as it relates to the kids, the families, and the Boca Jets Lacrosse sponsors. This is a very exciting time for the Boca Jets community." S. Finver, Boca Jets Lacrosse</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </Layout>
)